import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
//ESTILOS
import "primereact/resources/themes/saga-purple/theme.css";
import "primereact/resources/primereact.min.css"; //core css
// import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "react-toastify/dist/ReactToastify.css";
import { locale, addLocale } from "primereact/api";
import "./index.css";

addLocale("es", {
  firstDayOfWeek: 1,
  dayNames: [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ],
  dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
  monthNames: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ],
  monthNamesShort: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  passwordPrompt: "Ingrese una contraseña",
  dateFormat: "dd/mm/yy",
  today: "Hoy",
  clear: "Limpiar",
  accept: "Si",
  reject: "No",
  choose: "Seleccionar",
  upload: "Subir",
  cancel: "Cancelar",
  equals: "Es igual",
  notEquals: "No es igual",
  endsWith: "Termina con",
  contains: "Contiene",
  notContains: "No contiene",
  startsWith: "Comienza con",
  noFilter: "Sin filtro",
  // emptyMessage: "No se encontraron resultados",
  apply: "Buscar",
  matchAll: "Coincidir con todos",
  matchAny: "Coincidir con cualquiera",
  addRule: "Agregar regla",
});

locale("es");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
