import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "./Register.css";
//FORMIK
import * as Yup from "yup";
import { useFormik } from "formik";
//PRIMEREACT
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { InputMask } from "primereact/inputmask";
import { classNames } from "primereact/utils";
//COMPONENTES
import ABC from "../Others/ABC";
import ModalRegister from "../Others/ModalRegister";
// import Messages from "../Others/Messages";
import { toast } from "react-toastify";
import fglobales from "../../services/fglobales";
import regex from "../../services/regex";

function Register({ configurations, training }) {
  const [submitted, setSubmitted] = useState(false);
  const [registerM, setRegisterM] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  //CONTROL DE MENSAJES -->
  // const [showMessage, setShowMessage] = useState(false);
  // const [colorIcon, setColorIcon] = useState("");
  // const [headerMessage, setHeaderMessage] = useState("");
  // const [message, setMessage] = useState("");
  //CONTROL DE MENSAJES <--

  const formik = useFormik({
    initialValues: {
      names: "",
      dni: "",
      mobilePhone: "",
      emailFrom: "",
      // store: "",
      allies: "",
      branches: "",
      training: training,
      ppd: "",
    },
    validationSchema: Yup.object({
      names: Yup.string().required("El nombre completo es obligatorio"),
      dni: Yup.number().required("El documento de identidad es obligatorio"),
      mobilePhone: Yup.string()
        .matches(regex.mobilePhone, "El campo debe tener 10 dígitos")
        .required("Este campo es requerido"),
      emailFrom: Yup.string()
        .email("El correo electronico es invalido")
        .required("El correo electrónico es obligatorio"),
      // store: Yup.object().required("La tienda es obligatoria"),
      allies: Yup.object().required("El Aliado es obligatorio"),
      branches: Yup.object().required("La Sucursal es obligatoria"),
      ppd: Yup.boolean()
        .required("El tratamiento de datos debe ser aceptado.")
        .oneOf([true], "El tratamiento de datos, debe ser aceptado."),
    }),
    onSubmit: async (formData) => {
      console.log("🚀 ~ onSubmit: ~ formData:", formData)
      try {
        setSubmitted(true);

        const newUser = formData;
        newUser.dni = `${newUser.dni}`;
        newUser.dni = newUser.dni.trim();
        newUser.emailFrom = newUser.emailFrom.trim();
        newUser.email = `${newUser.dni}999@capacitaciontitanes.com`;
        newUser.password = `${newUser.dni}999`;
        newUser.phone = newUser.mobilePhone.replace(regex.clearCracter, "");

        if (!captchaValue) {
          toast.error("Se debe diligenciar el ReCAPTCHA");
          setSubmitted(false);
          return;
        }

        const dniExist = await fglobales.getExistNoIdTraining(
          "usersClients",
          "dni",
          formData.dni,
          "training.id",
          training.id
        );

        if (!dniExist) {
          const result = await fglobales.addDocs("usersClients", formData);
          setRegisterM(result.status);
          if (result.status) {
            formik.resetForm();
          } else {
            toast.error(result.message);
          }
           setSubmitted(false);
        } else {
          toast.error(
            "Este número de cédula ya se encuentra registrado en la capacitación."
          );
          setSubmitted(false);
        }
      } catch (error) {
        toast.error(error);
        console.log(error);
        setSubmitted(false);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  // const actionHiddenMessage = () => {
  //   setShowMessage(false);
  //   hideDialog();
  // };

  const hideDialog = () => {
    setRegisterM(false);
  };

  // const openTermin = () => {
  //   setColorIcon("secondary-color");
  //   setHeaderMessage("Tratamiento de datos personales.");
  //   setMessage(
  //     <>
  //       <div className="grid">
  //         <div className="col-12 md:px-6">
  //           <p>
  //             <a
  //               href="https://firebasestorage.googleapis.com/v0/b/slot-machine-new.appspot.com/o/Politica%20de%20tratamiento%20de%20datos%20JAMAR.pdf?alt=media&token=3195bdf8-bb1e-43fe-950d-dd5a4e5b83a4"
  //               target="_blank"
  //               rel="noopener noreferrer"
  //             >
  //               Tratamiento y protección de los datos personales
  //             </a>
  //           </p>
  //         </div>
  //       </div>

  //       <br />

  //       <div className="grid">
  //         <div className="col-12 px-2 md:px-6">
  //           <h2>Términos y condiciones:</h2>
  //           <br />

  //           <p className="mensajeP">
  //             - Los clientes potenciales e interesados que den los datos y sean
  //             registrados el formulario con potencial de compra pueden acceder a
  //             la ruleta y jugar.
  //           </p>

  //           <p className="mensajeP">
  //             - A los clientes que financiaron simplemente se les entrega un
  //             merchandising y lo registran en sus plantillas. Esta actividad
  //             iniciará desde el 26 julio de 2023.
  //           </p>
  //         </div>
  //       </div>
  //     </>
  //   );
  //   setShowMessage(true);
  // };

  // const citys = [
  //   { name: "JAMAR PRINCIPAL BARRANQUILLA", code: 0 },
  //   { name: "JAMAR NORTE BARRANQUILLA", code: 1 },
  // ];

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  return (
    <>
      <div className="grid grid-nogutter mt-5">
        <div className="col-10 col-offset-1 md:col-6 md:col-offset-3 container-form">
          <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="grid pb-2">
              <div className="col-12">
                <p className="pForm">Nombre completo: *</p>
                <InputText
                  id="names"
                  name="names"
                  value={formik.values.names}
                  onChange={formik.handleChange}
                  disabled={submitted}
                  className={classNames({
                    "p-invalid": isFormFieldValid("names"),
                    "input-filled":
                      formik.values.names && formik.values.names.trim() !== "",
                  })}
                />

                {getFormErrorMessage("names")}
              </div>
            </div>
            <div className="grid pb-2">
              <div className="col-12">
                <p className="pForm">Cédula: *</p>
                <InputText
                  id="dni"
                  name="dni"
                  type="tel"
                  value={formik.values.dni}
                  onChange={formik.handleChange}
                  disabled={submitted}
                  className={classNames({
                    "p-invalid": isFormFieldValid("dni"),
                    "input-filled":
                      formik.values.dni && formik.values.dni !== "",
                  })}
                />
                {getFormErrorMessage("dni")}
              </div>
            </div>
            <div className="grid pb-2">
              <div className="col-12">
                <p className="pForm">Télefono: *</p>
                <InputMask
                  id="mobilePhone"
                  name="mobilePhone"
                  type="tel"
                  mask="(999) 999-9999"
                  value={formik.values.mobilePhone}
                  onChange={(e) => formik.setFieldValue("mobilePhone", e.value)}
                  disabled={submitted}
                  className={classNames({
                    "p-invalid": isFormFieldValid("mobilePhone"),
                    "input-filled": formik.values.mobilePhone,
                  })}
                />

                {getFormErrorMessage("mobilePhone")}
              </div>
            </div>
            <div className="grid pb-2">
              <div className="col-12">
                <p className="pForm">Correo: *</p>
                <InputText
                  id="emailFrom"
                  name="emailFrom"
                  value={formik.values.emailFrom}
                  onChange={formik.handleChange}
                  disabled={submitted}
                  className={classNames({
                    "p-invalid": isFormFieldValid("emailFrom"),
                    "input-filled": formik.values.emailFrom,
                  })}
                />
                {getFormErrorMessage("emailFrom")}
              </div>
            </div>
            {/* <div className="grid pb-2">
                  <div className="col-12">
                    <p className="pForm">Tienda: *</p>
                    <Dropdown
                      optionLabel="name"
                      options={citys}
                      id="store"
                      name="store"
                      value={formik.values.store}
                      onChange={(e) => {
                        formik.setFieldValue("store", e.value);
                      }}
                      className={classNames(
                        {
                          "p-invalid": isFormFieldValid("store"),
                        },
                        "textForm"
                      )}
                      disabled={submitted}
                    />
                    {getFormErrorMessage("store")}
                  </div>
                </div> */}
            <ABC
              submitted={submitted}
              formik={formik}
              isFormFieldValid={isFormFieldValid}
              getFormErrorMessage={getFormErrorMessage}
            />
            <div className="grid pb-2">
              <div className="col-12">
                <div className="field-checkbox">
                  <Checkbox
                    onChange={(e) => formik.setFieldValue("ppd", e.checked)}
                    disabled={submitted}
                    checked={formik.values.ppd}
                    inputId="ppd"
                  />
                  <label
                    htmlFor="binary"
                    className={classNames(
                      {
                        "p-error": isFormFieldValid("ppd"),
                      },
                      "checked"
                    )}
                    // onClick={() => {
                    //   openTermin();
                    // }}
                  >
                    Confirmo lectura de términos y condiciones y consentimiento
                    informado de tratamiento de datos y uso de imagen. Estos se
                    podrán consultar y descargar en: www.titanesbrilla.com
                  </label>
                </div>
                {getFormErrorMessage("ppd")}
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="grid grid-nogutter mt-3">
        <div className="col-10 col-offset-1 text-center">
          <ReCAPTCHA
            sitekey={configurations.reCAPTCHA}
            style={{ display: "inline-block" }}
            onChange={handleCaptchaChange}
            className="reCAPTCHA"
          />
        </div>
      </div>

      <div className="grid grid-nogutter mt-6 mb-6">
        <div className="col-6 col-offset-3 md:col-2 md:col-offset-5 text-center">
          <button
            className="btn-basic"
            disabled={submitted}
            type="submit"
            onClick={() => {
              formik.submitForm();
            }}
          >
            Registrar asistencia
          </button>
        </div>
      </div>

      <ModalRegister registerM={registerM} hideDialog={hideDialog} />
    </>
  );
}

export default Register;
