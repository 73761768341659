/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
//PAGES
import Loader from "./pages/Loader";
import Error404 from "./pages/Error404";
import RegisterFree from "./pages/Register/RegisterFreeAsistencia";
import Header from "./pages/Header";
import Banner from "./pages/Banner";
//ESTILOS
import "./index.css";
//FIREBASE
import {
  onSnapshot,
  query,
  doc,
  collection,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "./firebase";

function App() {
  const [configurations, setConfigurations] = useState([]);
  const [theme, setTheme] = useState([]);
  const [training, setTraining] = useState([]);

  useEffect(() => {
    //Datos de configuración
    const qConfig = query(doc(db, "config", "configurations"));
    const qTheme = query(doc(db, "config", "theme"));
    const qTraining = query(
      collection(db, "training"),
      where("status", "==", true)
    );

    const unlistenqConfig = onSnapshot(qConfig, (docs) => {
      let configF = docs.data();
      setConfigurations(configF);
    });

    //Datos de Thema
    const unlistenqTheme = onSnapshot(qTheme, (docs) => {
      let configTh = docs.data();
      setTheme(configTh);
    });

    // Data Capacitación
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(qTraining);
        let data = [];
        querySnapshot.forEach((doc) => {
          data.push({ ...doc.data(), id: doc.data().id });
        });

        setTraining(data);
      } catch (error) {
        console.error("Error al obtener datos de capacitación:", error);
      }
    };

    fetchData();

    // Limpiar suscripciones
    return () => {
      unlistenqConfig();
      unlistenqTheme();
    };
  }, []);

  //Espera para mostrar Datos
  if (
    configurations.length === 0 ||
    theme.length === 0 ||
    training.length === 0
  ) {
    return <Loader typeLoader={0} />;
  }

  return (
    <>
      {configurations && configurations.on === false ? (
        <Error404 />
      ) : (
        <>
          <Header theme={theme} />
          <Banner training={training[0]} />
          <RegisterFree
            configurations={configurations}
            theme={theme}
            training={training[0]}
          />
        </>
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
