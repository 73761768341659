import React from "react";
//ESTILOS
import "./Header.css";
//PRIMEREACT

function Header({ theme }) {
  return (
    <>
      <div className="grid grid-nogutter">
        <div className="col-6 logo">
          <img src={theme ? theme.logo : "logo"} alt="Logo" />
        </div>
        <div className="col-6 logo2">
          <h1>Registro de asistencia</h1>
        </div>
      </div>
    </>
  );
}

export default Header;
