const regex = {};

regex.dni = /^[1-9]\d*?[0]*$/;
regex.mobilePhone = /^((\+0?1\s)?)\(?\d{3}\)?[\s.\s]\d{3}[\s.-]\d{4}$/;
regex.mail =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$[/ /]/;
regex.phone = /\(([^)]*)\)/g;
regex.space = /\s+/g;
regex.clearCracter = /\D/g;

export default regex;

