import React, { useEffect, useState } from "react";
// PRIMEREACT
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";
// PRIMEREACT
// SERVICIOS
import fglobales from "../../services/fglobales";
// SERVICIOS

function ABC({ submitted, formik, isFormFieldValid, getFormErrorMessage }) {
  const [dataAliados, setDataAliados] = useState([]);
  const [loadS, setLoadS] = useState(true);
  const [dataSucursales, setDataSucursales] = useState([]);
  const [loadM, setLoadM] = useState(true);

  useEffect(() => {
    async function getDepartments() {
      setLoadS(true);
      let data = await fglobales.getPrincipalList("allies", "allyName");
      setDataAliados(data);
      setLoadS(false);
    }
    getDepartments();
  }, []);

  useEffect(() => {
    async function getDepartments() {
      setLoadM(true);
      let data = await fglobales.getSecondaryList(
        "branches",
        formik.values.allies.alliedCode,
        "nameBranch"
      );
      setDataSucursales(data);
      setLoadM(false);
    }
    if (formik.values?.allies.alliedCode) {
      getDepartments();
    }
  }, [formik.values?.allies.alliedCode]);

  const getSucursales = async (alliedCode) => {
    setLoadM(true);
    let data = await fglobales.getSecondaryList(
      "branches",
      alliedCode,
      "nameBranch"
    );
    setDataSucursales(data);
    setLoadM(false);
  };

  const selectedAliadoTemplate = (option, props) => {
    if (option) {
      return <p className="listItemsFilter">{option.allyName}</p>;
    }

    return <span>{props.placeholder}</span>;
  };

  const selectedSucursalTemplate = (option, props) => {
    if (option) {
      return (
        <p className="listItemsFilter">
          {option.branchCode} - {option.nameBranch}
        </p>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  return (
    <>
      <div className="grid pb-2">
        <div className="col-12">
          <p className="pForm">Aliado: *</p>
          <Dropdown
            optionLabel="allyName"
            options={dataAliados}
            id="allies"
            name="allies"
            value={formik.values.allies}
            itemTemplate={selectedAliadoTemplate}
            filter
            onChange={(e) => {
              getSucursales(e.value.alliedCode);
              formik.setFieldValue("allies", e.value);
              formik.setFieldValue("branches", "");
            }}
            className={classNames(
              {
                "p-invalid": isFormFieldValid("allies"),
              },
              "textForm"
            )}
            disabled={submitted || loadS}
          />
          {getFormErrorMessage("allies")}
        </div>
      </div>
      <div className="grid pb-2">
        <div className="col-12">
          <p className="pForm">Sucursal: *</p>
          <Dropdown
            optionLabel="nameBranch"
            options={dataSucursales}
            id="branches"
            name="branches"
            value={formik.values.branches}
            filter
            itemTemplate={selectedSucursalTemplate}
            onChange={(e) => {
              formik.setFieldValue("branches", e.value);
            }}
            className={classNames(
              {
                "p-invalid": isFormFieldValid("branches"),
              },
              "textForm"
            )}
            disabled={submitted || loadM}
            // placeholder="Municipio*"
          />
          {getFormErrorMessage("branches")}
        </div>
      </div>
    </>
  );
}

export default ABC;
