import React from "react";
//ESTILOS
import "./Banner.css";
//PRIMEREACT

function Banner({ training }) {
  return (
    <>
      <header className="grid grid-nogutter">
        <div className="md:col-8 md:col-offset-2 banners visualEscritorio">
          <img
            src={training ? training.bannerEscritorio : "banner"}
            alt="banner"
          />
        </div>

        <div className="col-10 col-offset-1 banners visualMovil">
          <img src={training ? training.bannerMovil : "banner"} alt="banner" />
        </div>
      </header>
    </>
  );
}

export default Banner;
