import {
  query,
  where,
  // getDoc,
  getDocs,
  collection,
  updateDoc,
  doc,
  setDoc,
  serverTimestamp,
  runTransaction,
  increment,
  orderBy,
} from "firebase/firestore";
import { signOut } from "firebase/auth";
// import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { db, auth } from "../firebase";

const fglobales = {};

fglobales.getExistNoId = async (collectionText, variableText, value) => {
  const queryRef = query(
    collection(db, collectionText),
    where(variableText, "==", value)
  );
  const docSnap = await getDocs(queryRef);

  return docSnap.size > 0;
};

fglobales.getExistNoIdTraining = async (
  collectionText,
  variableText,
  value,
  variableText2,
  value2
) => {
  const queryRef = query(
    collection(db, collectionText),
    where(variableText, "==", value),
    where(variableText2, "==", value2)
  );
  const docSnap = await getDocs(queryRef);

  return docSnap.size > 0;
};

fglobales.logoutSesion = (setDataUser) => {
  signOut(auth)
    .then(function () {
      setDataUser(null);
    })
    .catch(function (error) {
      // An error happened.
      console.log(error);
    });
};

fglobales.addDocs = async (collectionText, data) => {
  const ref = doc(collection(db, collectionText));
  try {
    await setDoc(ref, {
      ...data,
      id: ref.id,
      timestamp: serverTimestamp(),
    });
    return {
      status: true,
      message: "Tu registro ha sido creado exitosamente",
    };
  } catch (e) {
    console.log("Transacción fallida: ", e);
    return {
      status: false,
      message: "Registro fallido. Vuelve a intentarlo.",
      messageSistema: e,
    };
  }
};

fglobales.addOrder = async (collectionText, data, dataUser) => {
  const configDocRef = doc(db, "config", "counters");
  const orderDocRef = doc(collection(db, collectionText));

  try {
    await runTransaction(db, async (transaction) => {
      const configDoc = await transaction.get(configDocRef);
      const order = configDoc.data().order;
      let number;

      number = order + 1;
      transaction.update(configDocRef, { order: increment(1) });

      transaction.set(orderDocRef, {
        ...data,
        id: orderDocRef.id,
        timestamp: serverTimestamp(),
        names: dataUser.names,
        surnames: dataUser.surnames,
        medio: dataUser.medio,
        number,
        status: 0,
      });
    });
    return { status: true, message: "Transación Exitosa" };
  } catch (e) {
    console.log("Transacción fallida: ", e);
    return { status: false, message: e };
  }
};

fglobales.getdata = async (collectionText) => {
  const querySnapshot = await getDocs(
    query(collection(db, collectionText), orderBy("order"))
  );
  let data = [];
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
};

fglobales.updateSecction = async (collectionText, id, data) => {
  const q = doc(db, collectionText, id);
  try {
    await updateDoc(q, { ...data });
    return true;
  } catch (e) {
    return false;
  }
};

//Consulta que retorna los aliados apra llenar lista con Filtro
fglobales.getPrincipalList = async (collectionText, variableOrder) => {
  const q = query(collection(db, collectionText), orderBy(variableOrder));

  const querySnapshot = await getDocs(q);
  let data = [];
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
};

fglobales.getSecondaryList = async (
  collectionText,
  variableText,
  variableOrder
) => {
  const q = query(
    collection(db, collectionText),
    where("alliedCode", "==", variableText),
    orderBy(variableOrder)
  );

  const querySnapshot = await getDocs(q);
  let data = [];
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
};

export default fglobales;
