import React from "react";
import PuffLoader from "react-spinners/PuffLoader";
import "./Loader.css";

function Loader({ typeLoader }) {
  return (
    <>
      <div
        className={
          typeLoader === 0 ? "containerLoader" : "containerLoaderSimple"
        }
      >
        <PuffLoader color="#fff" size={78} />
      </div>
    </>
  );
}

export default Loader;
