import React from "react";
// PRIMEREACT
import { Dialog } from "primereact/dialog";
// MEDIOS
import muchasImg from "../../assets/images/gracias.png";

export default function ModalWinners({ registerM, hideDialog }) {
  return (
    <Dialog
      visible={registerM}
      showHeader={false}
      position="center"
      style={{ width: "90vw", maxWidth: "600px" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
    >
      <>
        <div className="grid grid-nogutter">
          <div className="col-10 col-offset-1 graciasM">
            <img src={muchasImg} alt="muchasImg" />
            <p className="pForm text-center">por registrar tu asistencia.</p>
          </div>
        </div>

        <div className="grid grid-nogutter mt-4">
          <div className="col-6 col-offset-3 text-center">
            <button
              className="btn-basic"
              type="submit"
              onClick={() => {
                hideDialog();
              }}
            >
              Salir
            </button>
          </div>
        </div>
      </>
    </Dialog>
  );
}
